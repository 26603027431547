<template>
  <div class="lead-tradein">
    <div class="lead-tradein-info">
      <Heading tag="h3" :label="labels.title" />
      <TextLabel :label="labels.subtitle" />
    </div>
    <div class="lead-tradein-form">
      <InputField
        :key="'plate' + plate"
        :label="labels.inputs.plate"
        mode="plate"
        :value="plate"
        @onInput="(e) => handleEmit('plate', e)"
        ref="plate"
      />
      <div class="single-row">
        <div class="month">
          <Dropdown
            selectedOnHeader
            :items="months"
            :key="'monthKey'"
            :name="labels.inputs.month"
            @onSelectedItems="handleMonth"
          />
          <Indicator
            key="months-indicator-01"
            v-show="!validationFlags.month"
            :type="`error`"
            :label="labels.indicators.month"
            :icon="`exclamation-mark`"
          />
        </div>
        <div class="year">
          <Dropdown
            selectedOnHeader
            :items="years"
            :key="'yearKey'"
            :name="labels.inputs.year"
            @onSelectedItems="handleYear"
          />
          <Indicator
            key="year-indicator-01"
            v-show="!validationFlags.year"
            :type="`error`"
            :label="labels.indicators.year"
            :icon="`exclamation-mark`"
          />
        </div>
      </div>
      <Dropdown
        selectedOnHeader
        :key="'brandsKey'"
        :name="labels.inputs.brand"
        :items="brands"
        @onSelectedItems="(e) => handleEmit('brand', e)"
      />
      <Indicator
        key="brand-indicator-01"
        v-show="!validationFlags.brand"
        :type="`error`"
        :label="labels.indicators.brand"
        :icon="`exclamation-mark`"
      />
      <Indicator
        key="noModels-indicator-01"
        v-show="hasSelected(brands) && models.length < 1"
        :type="`info`"
        :label="labels.indicators.noModels"
        :icon="`exclamation-mark`"
      />
      <Dropdown
        v-if="hasSelected(brands) && models.length > 0"
        selectedOnHeader
        :key="'modelsKey'"
        :name="labels.inputs.model"
        :items="models"
        @onSelectedItems="(e) => handleEmit('model', e)"
      />
      <Indicator
        key="model-indicator-01"
        v-show="!validationFlags.model"
        :type="`error`"
        :label="labels.indicators.model"
        :icon="`exclamation-mark`"
      />
      <Dropdown
        v-if="hasSelected(models) && versions.length > 0"
        selectedOnHeader
        :key="'versionsKey'"
        :items="versions"
        :name="labels.inputs.version"
        @onSelectedItems="(e) => handleEmit('version', e)"
      />
      <Indicator
        key="version-indicator-01"
        v-show="!validationFlags.version"
        :type="`error`"
        :label="labels.indicators.version"
        :icon="`exclamation-mark`"
      />
      <InputField
        v-if="hasSelected(models) && hasSelected(versions)"
        :value="`${kilometers}`"
        :label="labels.inputs.kms"
        mode="kms"
        @onInput="(e) => handleEmit('kilometers', e)"
        :key="kmRenderKey"
        ref="kilometers"
      />
    </div>
  </div>
</template>

<script>
import {
  Heading,
  Dropdown,
  InputField,
  Indicator,
  TextLabel,
} from "@sc/ds-ui-component-library";
import { mapGetters } from "vuex";

const range = (n, from = 1) =>
  [...Array(n).keys()]
    .map((i) => i + from)
    .map((it) => ({ key: it, value: `${it}` }));

export default {
  name: "LeadTradein",
  components: { Heading, TextLabel, Dropdown, InputField, Indicator },
  data() {
    return {
      validationFlags: {
        month: true,
        year: true,
        brand: true,
        model: true,
        version: true,
      },
      firstLoad: true,
      kmRenderKey: 0,
      validating: false,
    };
  },
  props: {
    labels: { type: Object },
  },
  methods: {
    getFirst(arr) {
      const selected = arr.filter((it) => it.selected);
      if (selected.length) return selected[0];
      else return false;
    },
    handleMonth(e) {
      this.validationFlags.month = this.getFirst(e).selected;
      this.$store.commit(
        "TradeinModule/setMonth",
        this.getFirst(e) ? this.getFirst(e).value : ""
      );
    },
    handleYear(e) {
      this.validationFlags.year = this.getFirst(e).selected;
      this.$store.commit(
        "TradeinModule/setYear",
        this.getFirst(e) ? this.getFirst(e).value : ""
      );
    },
    handleDate(e) {
      this.$store.commit("TradeinModule/setPlateDate", e);
    },
    handleLicense([e, valid]) {
      if (valid && !this.validating) {
        this.$store.commit("TradeinModule/setPlate", e);
        this.$store.dispatch("TradeinModule/storeVehicleByPlate");
        this.kmRenderKey += 1;
      }
    },
    handleKilometers(e) {
      this.$store.commit("TradeinModule/setKilometersRetake", e[0]);
    },
    handleBrand(e) {
      this.validationFlags.brand = this.getFirst(e).selected;
      this.$store.commit(
        "TradeinModule/setBrand",
        this.getFirst(e) ? this.getFirst(e) : ""
      );
      if (this.getFirst(e)) this.$store.dispatch("TradeinModule/storeModels");
      if (!this.firstLoad) {
        this.$store.commit("TradeinModule/setModels", {});
        this.$store.commit("TradeinModule/setVersions", {});
        this.$store.commit("TradeinModule/setVersion", "");
      }
    },
    handleModel(e) {
      this.validationFlags.model = this.getFirst(e).selected;
      this.$store.commit(
        "TradeinModule/setModel",
        this.getFirst(e) ? this.getFirst(e) : ""
      );
      if (this.getFirst(e)) this.$store.dispatch("TradeinModule/storeVersions");
      if (!this.firstLoad) {
        this.$store.commit("TradeinModule/setVersions", {});
        this.$store.commit("TradeinModule/setVersion", "");
      }
    },
    handleVersion(e) {
      this.validationFlags.version = this.getFirst(e).selected;
      this.$store.commit(
        "TradeinModule/setVersion",
        this.getFirst(e) ? this.getFirst(e).value : ""
      );
      this.$store.commit(
        "TradeinModule/setBcaId",
        this.getFirst(e) ? this.getFirst(e).key : ""
      );
      this.$store.commit(
        "TradeinModule/setFuel",
        this.versions.find((el) => el.selected)?.fuel
      );
      this.$store.commit(
        "TradeinModule/setTransmission",
        this.versions.find((el) => el.selected)?.transmission
      );
    },
    handlePlate([plate, valid]) {
      if (valid && !this.validating) {
        this.$store.dispatch("TradeinModule/cleanTradein");
        this.$store.commit("TradeinModule/setPlate", plate);
        this.$store.dispatch("TradeinModule/storeVehicleByPlate");
      }
    },
    handleEmit(input, e) {
      switch (input) {
        case "kilometers":
          this.handleKilometers(e);
          break;
        case "brand":
          this.handleBrand(e);
          break;
        case "model":
          this.handleModel(e);
          break;
        case "version":
          this.handleVersion(e);
          break;
        case "plate":
          this.handlePlate(e);
          break;
        default:
      }
      this.$emit(
        "onValid",
        this.brand &&
          this.model &&
          this.version &&
          this.plate &&
          this.year &&
          this.month &&
          this.kilometers > 99
      );
    },
    formatDigit(n) {
      return n.toLocaleString(navigator.language, {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    },
    hasSelected(arr) {
      return arr.filter((val) => val.selected).length > 0;
    },
    showLabels(ref) {
      this.$refs[ref].forceValidation();
    },
    validateInputs() {
      this.validating = true;
      this.showLabels("plate");
      this.validationFlags.month = !!this.month;
      this.validationFlags.year = !!this.year;
      this.validationFlags.brand = !!this.brand;
      if (this.hasSelected(this.brands) && this.models.length > 0)
        this.validationFlags.model = !!this.model;
      if (this.hasSelected(this.models) && this.versions.length > 0)
        this.validationFlags.version = !!this.version;
      if (this.hasSelected(this.models) && this.hasSelected(this.versions))
        this.showLabels("kilometers");
      this.validating = false;
    },
  },
  computed: {
    ...mapGetters({
      plateDate: "TradeinModule/getPlateDate",
      plate: "TradeinModule/getPlate",
      brands: "TradeinModule/getBrands",
      models: "TradeinModule/getModels",
      versions: "TradeinModule/getVersions",
      brand: "TradeinModule/getBrand",
      model: "TradeinModule/getModell",
      version: "TradeinModule/getVersion",
      kilometers: "TradeinModule/getKilometersRetake",
      year: "TradeinModule/getYear",
      month: "TradeinModule/getMonth",
    }),
    months() {
      return range(12).map((it) =>
        parseInt(it.key) === parseInt(this.month)
          ? {
              key: this.formatDigit(it.key),
              value: this.formatDigit(it.key),
              selected: true,
            }
          : {
              key: this.formatDigit(it.key),
              value: this.formatDigit(it.key),
            }
      );
    },
    years() {
      return range(100, 1922)
        .reverse()
        .map((it) =>
          parseInt(it.key) === parseInt(this.year)
            ? { ...it, selected: true }
            : it
        );
    },
  },
  mounted() {
    this.handleEmit();
    this.firstLoad = false;
  },
};
</script>

<style scoped lang="scss">
.lead-tradein {
  --heading-color: var(--color-1);
  --heading-medium-font-size: 16px;
  --heading-medium-line-height: 16px;
  --text-label-color: var(--color-2);
  --text-label-font-size: 12px;
  --text-label-line-height: 16px;
}

.lead-tradein-info,
.lead-tradein-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  & > * {
    margin: 8px 0;
  }

  & > *:first-child {
    margin: 0 0 8px 0;
  }

  & > *:last-child {
    margin: 8px 0 0 0;
  }
}

.single-row {
  display: flex;
  width: 100%;
  justify-content: space-between;

  & > *:first-child {
    margin: 0 16px 0 0;
  }

  ::v-deep .dropdown--wrapper {
    width: 100%;
  }
}

.month,
.year {
  width: 100%;
}
</style>
