<template>
  <div class="tradein-page">
    <Section class="section-header" id="tradein-hero">
      <SectionHeader :title="$t('pages.retake.services.header.title')">
        <Heading
          class="label-evaluation"
          :label="$t('pages.retake.services.header.subtitle')"
          tag="h2"
        />
      </SectionHeader>
      <Card
        class="w-full md:py-10 md:pr-28 grid md:justify-items-end h-2/3"
        id="card"
        ref="licenseCard"
      >
        <TextLabel
          customClass="card-title"
          :label="$t('pages.retake.services.header.label')"
        />
        <InputField
          :label="$t('pages.retake.card.input')"
          mode="plate"
          :disabled="getSuccessfullPlateCall"
          @onInput="handleLicense"
          :key="plateInputRenderKey"
          ref="licenseInput"
        />
        <Button
          :label="$t('pages.retake.card.button')"
          @onClick="openPanel"
          :disabled="!isPlateValid"
        />
      </Card>
      <img
        :src="
          $t('resources.images.retakepage.hero') ||
          require('@/assets/images/stuff/Hero_Pag_Retoma.webp')
        "
        class="hero-image"
        :alt="$t('pages.retake.services.header.alt')"
      />
    </Section>
    <CpAftersalesServices
      :title="title"
      :subtitle="subtitle"
      :serviceCards="serviceCards"
    />
    <Section id="initButton" class="initButton">
      <Button
        type="secondary"
        label="Iniciar Avaliação"
        @onClick="goToLicenseCard"
      />
    </Section>
    <TrustpilotCarousel />
    <PanelTradein
      :addWeekendDays="true"
      @tradeInPanelClosed="onClosedPanel"
      :comesFromReserve="comesFromReserve"
      :vehicle="vehicle"
    />
  </div>
</template>

<script>
import {
  Section,
  SectionHeader,
  TextLabel,
  Card,
  InputField,
  Button,
  Heading,
} from "@sc/ds-ui-component-library";

import PanelTradein from "../../refactor/PanelTradein.vue";
import CpAftersalesServices from "../../refactor/CpAftersalesServices.vue";
import TrustpilotCarousel from "../../refactor/TrustpilotCarousel.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "RetakeEvaluationPage",
  components: {
    Section,
    SectionHeader,
    TextLabel,
    Card,
    InputField,
    Button,
    Heading,
    CpAftersalesServices,
    PanelTradein,
    TrustpilotCarousel,
  },
  metaInfo() {
    return {
      title: this.$t("meta.tradeIn.title"),
      meta: [
        { charset: "utf-8" },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.tradeIn.description"),
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.$t("meta.tradeIn.keywords"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.tradeIn.title"),
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.tradeIn.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.$t("meta.tradeIn.image"),
        },
      ],
    };
  },
  data() {
    return {
      plateInputRenderKey: 0,
      comesFromReserve: false,
      vehicle: {},
      license: "",

      isPlateValid: false,
    };
  },
  computed: {
    ...mapGetters({
      isOpenTradeinPanel: "PanelModule/isOpenTradein",
      getTradeInStep: "PanelModule/getTradeInStep",
      getDetailPageComesFromReserve:
        "PanelModule/getDetailPageComesFromReserve",
      getSuccessfullPlateCall: "TradeinModule/getSuccessfullPlateCall",
    }),
    title() {
      return this.$t("pages.retake.services.title");
    },
    subtitle() {
      return this.$t("pages.retake.services.subtitle");
    },
    serviceCards() {
      return [
        {
          image:
            this.$t("resources.images.retakepage.swiperCard1") ||
            require("@/assets/images/retake-page/old_vehicle.svg"),
          title: this.$t("pages.retake.services.0.title"),
          description: this.$t("pages.retake.services.0.description"),
          alt: this.$t("pages.retake.services.0.alt"),
        },
        {
          image:
            this.$t("resources.images.retakepage.swiperCard2") ||
            require("@/assets/images/retake-page/retake_vehicle.svg"),
          title: this.$t("pages.retake.services.1.title"),
          description: this.$t("pages.retake.services.1.description"),
          alt: this.$t("pages.retake.services.1.alt"),
        },
        {
          image:
            this.$t("resources.images.retakepage.swiperCard3") ||
            require("@/assets/images/retake-page/new_vehicle.svg"),
          title: this.$t("pages.retake.services.2.title"),
          description: this.$t("pages.retake.services.2.description"),
          alt: this.$t("pages.retake.services.2.alt"),
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      toggleTradein: "PanelModule/toggleTradein",
      setTradeInStep: "PanelModule/setTradeinStep",
    }),
    async handleLicense([e, valid]) {
      if (valid) {
        await this.$store.commit("TradeinModule/setPlate", e);
        await this.$store.dispatch("TradeinModule/storeVehicleByPlate");
        this.toggleTradein();
      }
      this.isPlateValid = valid;
      this.license = e;
    },
    openPanel() {
      this.$store.commit("TradeinModule/setPlate", this.license);
      this.$store.dispatch("TradeinModule/storeVehicleByPlate");
    },
    goToLicenseCard() {
      this.$refs.licenseCard.$el.scrollIntoView({ behavior: "smooth" });
      this.$nextTick(() => {
        document.getElementsByClassName("input--input")[0].focus();
      });
    },
    onClosedPanel() {
      this.plateInputRenderKey += 1;
      this.license = "";
      this.isPlateValid = false;
    },
  },
  mounted() {
    const loginAction = "?action=login";
    if (!sessionStorage.getItem("previousUrl").includes(loginAction)) {
      if (this.isOpenTradeinPanel) {
        this.toggleTradein();
        this.setTradeInStep(1);
      }
      this.$store.dispatch("TradeinModule/cleanTradein");
      this.$store.commit("setIncludeRetake", false);
      this.$store.commit("ReserveModule/setTradein", false);
    }

    this.comesFromReserve = this.getDetailPageComesFromReserve.check;
    this.vehicle = this.getDetailPageComesFromReserve.vehicle;

    this.$meta().refresh();
  },
};
</script>

<style lang="scss" scoped>
#tradein-hero {
  --section-padding: 32px;

  --title-font-size: 56px;
  --title-line-height: 73px;
  --text-label-font-size: 24px;
  --text-label-line-height: 31px;

  display: flex;
  flex-direction: column;
  position: relative;
  --section-bg-color: none;
}

::v-deep .section-header {
  display: flex;
  flex-direction: column;
  z-index: 2;

  & > *:first-child {
    margin: 0 0 16px 0;
  }
}

.hero-image {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  object-fit: cover;
}

#card {
  padding: 40px;
  display: flex;
  flex-direction: column;
  --text-label-font-size: 14px;
  --text-label-line-height: 14px;
  z-index: 2;

  & > * {
    margin: 12px 0;
  }

  & > *:first-child {
    margin: 0 0 12px 0;
  }

  & > *:last-child {
    margin: 12px 0 0 0;
  }
}

.card-title {
  --text-label-font-size: 24px;
  --text-label-line-height: 31px;
  --text-label-weight: 700;
}

::v-deep .button {
  justify-content: center;
}

#tradein-howitworks {
  --section-header-width: 100%;
  --section-padding: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;

  ::v-deep .section-header {
    align-items: center;
  }
}

.initButton {
  --section-padding: 0px 0px;
  --section-margin: 0px 0px 112px 0px;
  ::v-deep {
    .btn {
      margin: auto;
    }
  }
}

#section-5 {
  --section-margin: 0px 0px 80px 0px;
  --section-padding: 0px 215px;
  text-align: center;

  ::v-deep .header {
    margin-bottom: 16px;
  }

  ::v-deep .card {
    --card-background-color: var(--color-6);
    --heading-medium-font-weight: bold;
    --heading-medium-font-size: 28px;
    --heading-medium-line-height: 36px;

    --text-label-font-weight: 600;
    --text-label-font-size: 18px;
    --text-label-line-height: 23px;
  }
}

.label-evaluation {
  --heading-color: var(--color-1);
  --heading-large-font-weight: 600;
  --heading-large-font-size: 24px;
  --heading-large-line-height: 31px;
}

::v-deep {
  .section-header {
    --text-label-color: var(--color-1);
    --text-label-font-size: 21px;
    --text-label-line-height: 27px;
    --text-label-weight: 600;
    --text-label-color: var(--color-1);
  }
}

.tradein-page {
  background-color: var(--color-7);
}

.initButton {
  --section-bg-color: var(--color-7);
}

#section-carousel {
  --section-bg-color: var(--color-7);
}

::v-deep {
  .aftersalesservices-swapper {
    margin-top: 80px;
  }
}

@media (min-width: 1280px) {
  #tradein-hero {
    --section-padding: 120px 155px;
    flex-direction: row;
  }

  #card {
    max-width: 586px;
    margin-left: 167px;
  }

  #tradein-howitworks {
    --section-padding: 112px;
  }

  .howitworks-blocks {
    flex-direction: row;
    gap: 166px;
  }

  #tradein-hero {
    gap: 20px;
  }
}

@media (max-width: 1680px) {
  #card {
    max-width: 500px;
    margin-left: 0px;
    padding: 24px;
  }

  #section-5 {
    --section-padding: 52px;
    --heading-large-font-size: 38px;
    --heading-large-line-height: 46px;
    --heading-large-weight-bold: 700;
    --section-header-width: 70%;
    --section-margin: 0px;
    padding-top: 60px;

    ::v-deep .card--content {
      --heading-medium-font-size: 21px;
      --heading-medium-line-height: 21px;
      --heading-weight-bold: 700;

      --text-label-font-size: 16px;
      --text-label-line-height: 20px;
      --text-label-weight: 500;
    }

    ::v-deep .section-header {
      --text-label-font-size: 20px;
      --text-label-line-height: 25px;
      --text-label-weight: 600;
    }

    ::v-deep .swiper-wrapper {
      --swiper-wrapper-justify: center;
      --aftersalesservices-swiper-slide-justify: center;
    }

    ::v-deep .aftersalesservices-swapper {
      margin-top: 52px;
    }
  }

  #tradein-hero {
    --section-header-width: 50%;
    --section-padding: 100px 130px;
    --title-font-size: 48px;
    --title-line-height: 66px;
    --title-weight-bold: 700;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
  }

  .label-evaluation {
    --heading-large-font-weight: 600;
    --heading-large-font-size: 20px;
    --heading-large-line-height: 25px;
  }

  .initButton {
    --section-margin: 0;
    --section-padding: 52px;
  }

  .card-title {
    --text-label-font-size: 20px;
    --text-label-line-height: 25px;
  }
}
@media (max-width: 1440px) {
  #tradein-hero {
    flex-direction: row;
  }

  ::v-deep .swiper-wrapper {
    --swiper-wrapper-justify: center;
  }

  #card {
    max-width: 400px;
    margin-left: 0px;
  }

  #section-5 {
    --section-padding: 52px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;
    --heading-large-weight-bold: 700;
    --section-header-width: 80%;
    --section-margin: 0px;
    padding-top: 60px;

    ::v-deep .card--content {
      --heading-medium-font-size: 21px;
      --heading-medium-line-height: 21px;
      --heading-weight-bold: 700;

      --text-label-font-size: 16px;
      --text-label-line-height: 20px;
      --text-label-weight: 500;
    }

    ::v-deep .section-header {
      --text-label-font-size: 18px;
      --text-label-line-height: 23px;
      --text-label-weight: 600;
    }
  }

  #tradein-hero {
    --section-header-width: 50%;
    --section-padding: 52px;
    --title-font-size: 38px;
    --title-line-height: 49px;
    --title-weight-bold: 700;
    align-items: center;
    justify-content: space-evenly;
  }

  .label-evaluation {
    --heading-large-font-weight: 600;
    --heading-large-font-size: 18px;
    --heading-large-line-height: 23px;
  }

  .card-title {
    --text-label-font-size: 18px;
    --text-label-line-height: 23px;
  }

  #card {
    padding: 24px;
  }

  #section-carousel {
    --section-padding: 52px;
    gap: 52px;

    ::v-deep .carousel {
      gap: 52px;
      --heading-weight-bold: 700;
      --heading-large-font-size: 28px;
      --heading-large-line-height: 36px;
    }
  }
}
@media (max-width: 1367px) {
  #section-5 {
    --section-padding: 40;

    ::v-deep .aftersalesservices-swapper {
      margin-top: 40px;
    }
  }

  #tradein-hero {
    --section-padding: 40px;
  }

  .initButton {
    --section-padding: 40px;
  }

  #section-carousel {
    --section-padding: 40px;
    gap: 40px;

    ::v-deep .carousel {
      gap: 40px;
    }
  }
}
@media (max-width: 1280px) {
  #section-5 {
    --section-padding: 28px;

    ::v-deep .aftersalesservices-swapper {
      margin-top: 28px;
    }
  }

  #tradein-hero {
    --section-padding: 28px;
  }

  .initButton {
    --section-padding: 28px;
  }

  #section-carousel {
    --section-padding: 28px;
    gap: 28px;

    ::v-deep .carousel {
      gap: 28px;
    }
  }
}
@media (max-width: 1024px) {
  #section-5 {
    --section-padding: 0px 16px;
    --section-header-width: 100%;

    ::v-deep .swiper-wrapper {
      --swiper-wrapper-justify: left;
      --aftersalesservices-swiper-slide-justify: left;
    }
  }

  #tradein-hero {
    --section-header-width: 100%;
  }

  #card {
    max-width: 100%;
    margin-left: 0px;
  }

  ::v-deep {
    .aftersalesservices-swapper {
      margin-top: 20px;
    }
  }
}
@media (max-width: 768px) {
  #tradein-hero {
    --section-padding: 60px 20px;
    flex-direction: column;
  }

  .section-header {
    display: inline-block;
    text-align: left;
  }

  .label-evaluation {
    font-size: 18px;
    line-height: 23px;
    --heading-xlarge-font-size: 32px;
    --heading-xlarge-line-height: 42px;
    --heading-weight-bold: 700;
    letter-spacing: normal;
    font-style: normal;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 1.25rem;
  }

  #section-5 {
    --section-margin: 0px 0px 30px 0px;
  }

  .initButton {
    --section-padding: 0px 16px;
    --section-margin: 0px 0px 60px 0px;
    --button-justify-content: center;

    ::v-deep {
      .btn {
        width: 100%;
      }
    }
  }

  ::v-deep .title {
    --title-font-size: var(--title-3-font-size);
    --title-line-height: var(--title-3-line-height);
    --title-weight: var(--font-weight-bold);
  }

  .tradein-page {
    --section-header-width: 100%;
  }

  ::v-deep .card-title {
    --text-label-font-size: var(--subtitle-1-font-size);
    --text-label-line-height: var(--subtitle-1-line-height);
    --text-label-weight: var(--font-weight-bold);
  }
}
</style>
