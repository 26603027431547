var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Panel',{key:'tradeinpanel' + _vm.renderKey,attrs:{"opened":_vm.getIsOpenTradeinPanel},on:{"clickOutside":_vm.resetPanel}},[_c('PanelHeader',{attrs:{"title":_vm.title,"closeButton":""},on:{"onClosePanel":_vm.resetPanel}}),_c('PanelContent',{class:[
      'panel-content',
      _vm.getTradeInStep === 4 ? 'panel-without-footer' : '',
    ]},[_c('div',{key:'leadsDiv' + _vm.renderKey,staticClass:"tradein-panel-content"},[(_vm.getTradeInStep <= 4)?_c('MultiStepProgressBar',{attrs:{"currentStep":_vm.getTradeInStep,"totalSteps":4}}):_vm._e(),(_vm.getTradeInStep === 1)?_c('LeadTradein',{ref:"tradein",attrs:{"labels":_vm.$t('pages.retake.panel.labels.tradein')},on:{"onValid":(e) => _vm.validateStep(1, e)}}):_vm._e(),(_vm.getTradeInStep === 2 && _vm.isGuest)?_c('LeadLogin',{attrs:{"labels":_vm.$t('panels.leadLogin')},on:{"onLogin":_vm.onLogin,"onRegister":_vm.onRegister}}):_vm._e(),(_vm.getTradeInStep === 2 && !_vm.isGuest)?_c('LeadUser',{key:'userlead' + _vm.renderKey,ref:"user",attrs:{"labels":_vm.labels,"info":_vm.user,"message":_vm.$t('pages.retake.userInfo.message'),"subtitle":_vm.$t('pages.retake.userInfo.subtitle')},on:{"onPhone":(e) => {
            this.phone = e;
            _vm.validateStep(2, this.phone[1]);
          }}}):_vm._e(),(_vm.getTradeInStep === 3)?_c('LeadSell',{ref:"sell",attrs:{"sellAdvTitle":_vm.$t('pages.retake.step3.valuationAdvantages.title'),"sellAdvItem1":_vm.$t('pages.retake.step3.valuationAdvantages.item1'),"sellAdvItem2":_vm.$t('pages.retake.step3.valuationAdvantages.item2'),"sellAdvFooter":_vm.$t('pages.retake.step3.valuationAdvantages.footer'),"options":_vm.sellOpts,"labels":_vm.labels,"dealers":_vm.dealers},on:{"onSell":_vm.handleSell,"dealer":_vm.handleDealer}}):_vm._e(),(_vm.getTradeInStep === 4)?_c('LeadValue',{attrs:{"value":_vm.valuation,"sell":_vm.sell,"labels":_vm.$t('panels.reserve')},on:{"onSearch":_vm.handleSearch,"onOnline":_vm.handleOnline},scopedSlots:_vm._u([{key:"cardArea",fn:function(){return [(_vm.comesFromReserve)?_c('div',{staticClass:"content"},[_c('Title',{attrs:{"size":"small","label":_vm.$t('pages.retake.goBackToDetail')}}),_c('div',{staticClass:"grid-wrapper"},[_c('CpVehicleCard',{attrs:{"v":_vm.vehicle}})],1)],1):_vm._e()]},proxy:true}],null,false,544503012)}):_vm._e(),(_vm.getTradeInStep === 5)?_c('LeadSellForm',{ref:"sellForm",attrs:{"message":_vm.$t('pages.retake.sellForm.message'),"labels":_vm.labels,"info":_vm.user,"subtitlePersonal":_vm.subtitles.sellForm.personal,"subtitleLocation":_vm.subtitles.sellForm.location,"subtitleHour":_vm.subtitles.sellForm.hours,"dealers":_vm.dealers,"addWeekendDays":true},on:{"onInput":_vm.handleSellForm}}):_vm._e()],1)]),(_vm.getTradeInStep !== 4)?_c('PanelFooter',[_c('Button',{attrs:{"label":_vm.nextButton,"type":"secondary"},on:{"onClick":_vm.handleInc}}),(_vm.getTradeInStep > 1)?_c('Button',{attrs:{"label":"Anterior","type":"tertiary"},on:{"onClick":_vm.handleDec}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }