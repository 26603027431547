<template>
  <Panel
    :key="'tradeinpanel' + renderKey"
    :opened="getIsOpenTradeinPanel"
    @clickOutside="resetPanel"
  >
    <PanelHeader :title="title" closeButton @onClosePanel="resetPanel">
    </PanelHeader>
    <PanelContent
      :class="[
        'panel-content',
        getTradeInStep === 4 ? 'panel-without-footer' : '',
      ]"
    >
      <div class="tradein-panel-content" :key="'leadsDiv' + renderKey">
        <MultiStepProgressBar
          :currentStep="getTradeInStep"
          v-if="getTradeInStep <= 4"
          :totalSteps="4"
        />
        <LeadTradein
          ref="tradein"
          v-if="getTradeInStep === 1"
          @onValid="(e) => validateStep(1, e)"
          :labels="$t('pages.retake.panel.labels.tradein')"
        />
        <LeadLogin
          @onLogin="onLogin"
          @onRegister="onRegister"
          v-if="getTradeInStep === 2 && isGuest"
          :labels="$t('panels.leadLogin')"
        />
        <LeadUser
          ref="user"
          v-if="getTradeInStep === 2 && !isGuest"
          :key="'userlead' + renderKey"
          :labels="labels"
          :info="user"
          :message="$t('pages.retake.userInfo.message')"
          :subtitle="$t('pages.retake.userInfo.subtitle')"
          @onPhone="
            (e) => {
              this.phone = e;
              validateStep(2, this.phone[1]);
            }
          "
        />
        <LeadSell
          @onSell="handleSell"
          @dealer="handleDealer"
          :sellAdvTitle="$t('pages.retake.step3.valuationAdvantages.title')"
          :sellAdvItem1="$t('pages.retake.step3.valuationAdvantages.item1')"
          :sellAdvItem2="$t('pages.retake.step3.valuationAdvantages.item2')"
          :sellAdvFooter="$t('pages.retake.step3.valuationAdvantages.footer')"
          v-if="getTradeInStep === 3"
          :options="sellOpts"
          ref="sell"
          :labels="labels"
          :dealers="dealers"
        />
        <LeadValue
          :value="valuation"
          @onSearch="handleSearch"
          v-if="getTradeInStep === 4"
          :sell="sell"
          :labels="$t('panels.reserve')"
          @onOnline="handleOnline"
        >
          <template v-slot:cardArea>
            <div class="content" v-if="comesFromReserve">
              <Title size="small" :label="$t('pages.retake.goBackToDetail')" />
              <div class="grid-wrapper">
                <CpVehicleCard :v="vehicle" />
              </div>
            </div>
          </template>
        </LeadValue>
        <LeadSellForm
          v-if="getTradeInStep === 5"
          :message="$t('pages.retake.sellForm.message')"
          :labels="labels"
          :info="user"
          :subtitlePersonal="subtitles.sellForm.personal"
          :subtitleLocation="subtitles.sellForm.location"
          :subtitleHour="subtitles.sellForm.hours"
          :dealers="dealers"
          @onInput="handleSellForm"
          ref="sellForm"
          :addWeekendDays="true"
        />
      </div>
    </PanelContent>
    <PanelFooter v-if="getTradeInStep !== 4">
      <Button @onClick="handleInc" :label="nextButton" type="secondary" />
      <Button
        v-if="getTradeInStep > 1"
        @onClick="handleDec"
        label="Anterior"
        type="tertiary"
      />
    </PanelFooter>
  </Panel>
</template>

<script>
import {
  Panel,
  PanelHeader,
  PanelContent,
  PanelFooter,
  Button,
  LeadUser,
  LeadLogin,
  LeadSell,
  LeadValue,
  MultiStepProgressBar,
  LeadSellForm,
  Title,
} from "@sc/ds-ui-component-library";

import { mapGetters, mapMutations } from "vuex";

import LeadTradein from "../refactor/LeadTradein.vue";
//import validate from "../../utils/validator";
import CpVehicleCard from "./CpVehicleCard.vue";
import { FOOTPRINT_TYPES, registerEvent } from "../../api/footprint";
import { evaluationStepEvent } from "../../utils/googleTagManager";

const WEEKLY_RETAKE_LIMIT = -10;
export default {
  name: "PanelTradein",
  components: {
    Panel,
    PanelHeader,
    PanelContent,
    PanelFooter,
    Button,
    LeadTradein,
    LeadUser,
    LeadLogin,
    MultiStepProgressBar,
    LeadSell,
    LeadValue,
    LeadSellForm,
    CpVehicleCard,
    Title,
  },
  props: {
    vehicle: {
      type: Object,
    },
    comesFromReserve: {
      type: Boolean,
    },
  },
  watch: {
    user() {
      this.renderKey += 1;
    },
    getTradeInStep(n) {
      this.sendEvent(n);
    },
    isOpenTradeinPanel(n) {
      if (n) this.sendEvent(null);
    },
  },
  data() {
    return {
      selectedDealer: "",
      renderKey: 0,
      sell: null,
      validSteps: {
        1: false,
        2: false,
        3: false,
        4: true,
        5: false,
      },
      phone: [this.personal?.phone || "", !!this.personal?.phone],
      sellForm: {},
    };
  },
  methods: {
    ...mapMutations({
      toggleTradein: "PanelModule/toggleTradein",
      setAlert: "setAlert",
      setTradeInStep: "PanelModule/setTradeinStep",
      setDetailPageComesFromReserve:
        "PanelModule/setDetailPageComesFromReserve",
    }),
    sendEvent(pg) {
      let intention = "";
      if (this.sell !== null) {
        this.sell
          ? (intention = this.sellOpts[0]?.label)
          : (intention = this.sellOpts[1]?.label);
      } else intention = undefined;

      const payload = {
        brand: this.getBrand.text,
        model: this.getModel,
        version: this.getVersion,
        vehicle_year: this.getYear,
        kilometers: this.getKilometersRetake,
        dealer: this.selectedDealer,
        intention: intention,
      };
      switch (pg) {
        case 2:
          evaluationStepEvent(payload, 2, !this.isGuest);
          break;
        case 3:
          evaluationStepEvent(payload, 3, true);
          break;
        case 4:
          evaluationStepEvent(payload, 4, true);
          break;
        default:
          evaluationStepEvent(payload, 1, true);
          break;
      }
    },
    handleOnline(sellOnline) {
      this.$store.dispatch("TradeinModule/updateToSell", !sellOnline);
      if (sellOnline) {
        this.$router.push({
          name: "returnsDetails",
          params: { id: this.tradeinId },
        });
      } else {
        this.setTradeInStep(this.getTradeInStep + 1);
      }
    },
    validateStep(step, valid) {
      if (step === 3 && valid) {
        this.$store.commit("setUserPhoneNumber", this.phone[0]);
      }
      this.validSteps[step] = valid;
    },
    async handleInc() {
      let response;
      if (this.getTradeInStep === 1) {
        if (!this.validSteps[1]) {
          this.$refs.tradein.validateInputs();
          return;
        }
        registerEvent(FOOTPRINT_TYPES.RETAKE_STEP_1, {
          plate: this.getPlate.replaceAll("-", ""),
          brand: this.getBrand.text,
          model: this.getModel,
          version: this.getVersion,
          kilometers: this.getKilometersRetake,
          plateDate: this.getMonth + "-" + this.getYear,
        });
      }

      if (this.getTradeInStep === 5) {
        if (!this.validSteps[5]) {
          this.$refs.sellForm.validateInputs();
          return;
        }
        this.$store.dispatch("TradeinModule/sellVehicle", this.sellForm);
        this.resetPanel();
        return;
      }

      if (this.getTradeInStep === 3) {
        if (!this.validSteps[3] || this.getDealer === "") {
          this.$refs.sell.validateInputs();
          return;
        }

        const userInfo = {
          name: this.personal.name,
          email: this.personal.email,
          phoneNumber: this.phone[0],
          tradeInType: this.sell ? 2 : 1,
        };

        registerEvent(FOOTPRINT_TYPES.RETAKE_STEP_3, {
          tradeInType: this.sell
            ? this.sellOpts[0]?.label
            : this.sellOpts[1]?.label,
        });

        this.$store.commit("spinnerModule/setLoading", true); // -> 'someMutation'
        response = await this.$store
          .dispatch("TradeinModule/storeValuation", userInfo)
          .then(async (data) => {
            this.$store.commit("TradeinModule/setValuation", data);
            await this.$store.dispatch("TradeinModule/assignValuationToUser");
            this.$store.dispatch("storeGetAllReturns");

            this.setAlert({
              message: this.$t("toast.valuation.success.submited"),
              type: "info",
              display: true,
              global: true,
            });

            registerEvent(FOOTPRINT_TYPES.RETAKE_EVAL, {
              ...data,
            });

            return { error: false };
          })
          .catch((error) => {
            if (error.code === WEEKLY_RETAKE_LIMIT) {
              this.setAlert({
                message: this.$t("toast.valuation.weeklyLimit"),
                type: "info",
                display: true,
                global: true,
              });
            } else {
              this.setAlert({
                message: this.$t("toast.valuation.error.generic"),
                type: "error",
                display: true,
                global: true,
              });
            }
            registerEvent(FOOTPRINT_TYPES.RETAKE_EVAL, {
              ...error,
            });
            return { error: true };
          });
        this.$store.commit("spinnerModule/setLoading", false);
      }

      if (this.getTradeInStep === 2) {
        if (!this.validSteps[2]) {
          this.$refs.user.validateInputs();
          return;
        }
        const payload = {
          name: this.personal.name,
          email: this.personal.email,
          phoneNumber: this.phone,
        };
        registerEvent(FOOTPRINT_TYPES.RETAKE_STEP_2, payload);
      }

      if (response && response.error) {
        this.setTradeInStep(3);
      } else {
        this.setTradeInStep(this.getTradeInStep + 1);
      }
    },
    handleDec() {
      this.setTradeInStep(this.getTradeInStep - 1);
    },
    resetPanel() {
      this.setDetailPageComesFromReserve({
        check: false,
        vehicle: {},
      });
      this.setTradeInStep(1);
      this.validateStep(this.getTradeInStep, true);
      this.toggleTradein();
      this.$store.dispatch("TradeinModule/cleanTradein");
      this.$store.commit("setIncludeRetake", false);
      this.$store.commit("ReserveModule/setTradein", false);
      this.renderKey += 1;
      this.$emit("tradeInPanelClosed");
    },
    onLogin() {
      document.querySelectorAll("[data-idgo-login]")[0].click();
    },
    onRegister() {
      document.querySelectorAll("[data-idgo-registration]")[0].click();
    },
    handleSearch() {
      this.$router.push({
        name: "SearchPage",
      });
    },
    handleSell(e) {
      this.sell = e[0] === 0 && e[1];
      this.validateStep(3, e[1]);
      this.$store.commit("TradeinModule/setDealer", "");
    },
    async handleDealer(e) {
      const dealer = e.selected ? e.key : "";
      await this.$store.commit("TradeinModule/setDealer", dealer);
      this.selectedDealer = e.selected ? e.value : "";
    },
    handleSellForm(e) {
      this.sellForm = e;
      this.validateStep(this.getTradeInStep, e.valid);
    },
  },
  computed: {
    ...mapGetters({
      isGuest: "isGuest",
      isOpenTradeinPanel: "PanelModule/isOpenTradein",
      valuation: "TradeinModule/getValuation",
      tradeinId: "TradeinModule/getTradeInId",
      getPlate: "TradeinModule/getPlate",
      getMonth: "TradeinModule/getMonth",
      getYear: "TradeinModule/getYear",
      getBrand: "TradeinModule/getBrand",
      getModel: "TradeinModule/getModell",
      getDealer: "TradeinModule/getDealer",
      getVersion: "TradeinModule/getVersion",
      getKilometersRetake: "TradeinModule/getKilometersRetake",
      dealers: "SearchModule/dropdown/getDealersCeV",
      getTradeInStep: "PanelModule/getTradeInStep",
      personal: "UserModule/getPersonal",
    }),
    validStep() {
      if (this.getTradeInStep === 2) return this.isGuest;
      return !this.validSteps[this.getTradeInStep];
    },
    title() {
      switch (this.getTradeInStep) {
        case 1:
          return this.$t("pages.retake.panel.steps.1.title");
        case 2:
          return this.isGuest
            ? this.$t("pages.retake.panel.steps.2.guestTitle")
            : this.$t("pages.retake.panel.steps.2.title");
        case 3:
          return this.$t("pages.retake.panel.steps.3.title");
        case 4:
          return this.$t("pages.retake.panel.steps.4.title");
        case 5:
          return this.$t("pages.retake.panel.steps.5.title");
        default:
          return "";
      }
    },
    subtitles() {
      return this.$t("pages.retake.panel.subtitles");
    },
    labels() {
      return this.$t("pages.retake.panel.labels");
    },
    user() {
      const information = {};
      information.name = this.personal.name || "";
      information.email = this.personal.email || "";
      information.phone = this.personal.phone || "";
      return information;
    },
    sellOpts() {
      return [
        {
          label: this.$t("pages.retake.panel.sellOpts.1"),
          checked: false,
        },
        {
          label: this.$t("pages.retake.panel.sellOpts.2"),
          checked: false,
        },
      ];
    },
    nextButton() {
      switch (this.getTradeInStep) {
        case 3:
          return this.$t("pages.retake.panel.steps.3.nextButton");
        default:
          return this.$t("pages.retake.panel.nextButton");
      }
    },
    getIsOpenTradeinPanel() {
      this.isOpenTradeinPanel
        ? document.body.classList.add("no-scroll")
        : document.body.classList.remove("no-scroll");
      return this.isOpenTradeinPanel;
    },
  },
  mounted() {
    const loginAction = "?action=login";
    setTimeout(async () => {
      if (sessionStorage.getItem("previousUrl").includes(loginAction)) {
        if (this.getTradeInStep === 2) {
          this.setTradeInStep(2);
          this.sendEvent(2);
        }
      }
    }, 3000);
  },
};
</script>

<style scoped lang="scss">
::v-deep .panel--header {
  margin-bottom: 40px;

  &:after {
    content: none;
  }
}
.panel-content {
  --panel-header-height: 48px;
}

.panel-without-footer {
  --panel-footer-height: 0px;
}

.tradein-panel-content {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    margin: 20px 0;
  }

  & > *:first-child {
    margin: 0 0 20px 0;
  }
}

.panel--header-inner::after {
  content: none;
}

::v-deep .step-progress {
  width: 100%;
}

::v-deep .panel--content {
  margin-top: 40px;

  .button {
    justify-content: center;
  }
}

::v-deep .panel--footer {
  .button {
    width: 50%;
    justify-content: center;
  }

  .panel--footer-inner {
    flex-direction: row-reverse;

    & > *:first-child {
      margin-left: 16px;
    }

    .button {
      padding: 19px 30px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;
  --title-small-font-size: 24px;
  align-items: center;

  & > * {
    margin: 10px 0;
  }

  & > *:first-child {
    margin: 0 0 10px 0;
  }

  & > *:last-child {
    margin: 10px 0 0 0;
  }
}

::v-deep .card--container > .header {
  max-height: 150px;
}

::v-deep .panel-vehicle-card > .content {
  max-height: 180px !important;
}

::v-deep .model {
  --title-font-size: 18px;
  --title-line-height: 18px;
}

::v-deep .designation {
  --subtitle-font-size: 14px;
  --subtitle-line-height: 14px;
}

::v-deep .ellipse {
  margin: 8px;
}

::v-deep .left {
  --text-label-font-size: 12px;
  --text-label-line-height: 12px;
}

::v-deep .now {
  --text-label-font-size: 21px;
  --text-label-line-height: 21px;
}

.grid-wrapper {
  --text-label-font-size: 10px;
  max-width: 380px;
}
@media (max-width: 1440px) {
  .grid-wrapper {
    --text-label-font-size: 10px;
    max-width: 320px;
  }
}
@media (max-width: 768px) {
  _::-webkit-full-page-media,
  ::v-deep .panel.opened {
    --panel-height: 100%;
  }
}
</style>
